import { useFormik } from "formik";
import { Password } from "primereact/password";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FactorFlow from "../../Images/Logo.png";
import { loginUser, resetChangeStatus } from "../../redux/auth_slice/login_user_slice";
import GlobalInputField from "../../ui-components/globalinputfield";
import PrimaryButton from "../../ui-components/primarybutton/primarybutton";
import "./Login.css";

const Login = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    //redux
    const loginUserReducer = useSelector((state) => state.loginUser);
    const { success, error, loading, logoutSuccess } = loginUserReducer;

    //forms
    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required.").min(8, "Maximum length should be 8"),
        // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
        email: Yup.string().required("Email is required."),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            dispatch(loginUser(data));
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                // toast.success("Logged in successfully");
                history.push("/company-management");
            } else {
                toast.warn(error);
            }
        }
        return () => {
            dispatch(resetChangeStatus());
        };
    }, [success, error]);

    useEffect(() => {
        if (logoutSuccess !== undefined) {
            if (logoutSuccess === true) {
                // toast.warn("Logged out successfully");
                history.push("/");
            } else {
                toast.warn(error);
            }
        }
        return () => {
            dispatch(resetChangeStatus());
        };
    }, [logoutSuccess, error]);

    return (
        <>
            <div className="background_image">
                <div className="grid">
                    <div className="lg:col-7 md:col-6"></div>
                    <div className="lg:col-5 md:col-6 col-12">
                        <div className="custom_margin_top">
                            <div className="res_img">
                                <img src={FactorFlow} alt="Factor Flow Logo" />
                            </div>
                            <div className="pt-2">
                                <h4>One tool for your whole team.</h4>
                            </div>
                        </div>

                        {/* Container Section */}

                        <div className="container">
                            <p className="sign_in_text">Sign In</p>
                            <p className="sign_text">Enter your Email & Password to Login</p>

                            <div className="pt-3">
                                {/* input section  */}

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-3">
                                        <label className="email-lbl">Email</label>
                                        <GlobalInputField id="email" name="email" value={formik.values.email} onChange={formik.handleChange} autoFocus style={{ marginTop: "10px" }} type="text" placeholder="Enter Email" />
                                        {getFormErrorMessage("email")}
                                    </div>
                                    <div className="mb-3">
                                        <label className="email-lbl">Password</label>
                                        <Password className="login_input input_pass" style={{ marginTop: "10px" }} value={formik.values.password} onChange={formik.handleChange} name="password" id="password" type="password" placeholder="Enter Password" toggleMask />
                                        {getFormErrorMessage("password")}
                                    </div>
                                    {/* button section */}
                                    <div className="flex flex-row justify-content-center mt-5">
                                        {/* <p
                                        className="forget_text"
                                        onClick={(e) => {
                                            history.push("/forgetpass");
                                        }}
                                    >
                                        Forgot password?
                                    </p> */}

                                        <PrimaryButton loading={loading} type="submit" iconPos="right" label="Sign In" disabled={loading} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
