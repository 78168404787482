import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppConfig } from "./AppConfig";
import { AppMenu } from "./AppMenu";
import { AppTopbar } from "./AppTopbar";

import Dashboard from "./components/Dashboard";
import Login from "./pages/login/Login";
import ProductRights from "./pages/productRight";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "prismjs/themes/prism-coy.css";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import "./assets/demo/Demos.scss";
import "./assets/demo/flags/flags.css";
import "./assets/layout/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "./service/axios_apis";
import { fetchUserDetails } from "./redux/auth_slice/login_user_slice";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    const dispatch = useDispatch();

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            items: [
                {
                    label: "Company Management",
                    icon: "pi pi-fw pi-list",
                    to: "/company-management",
                },
                {
                    label: "Product Rights",
                    icon: "pi pi-fw pi-lock",
                    to: "/product-management",
                },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });
    const loginUser = useSelector((state) => state.loginUser);
    let { user } = loginUser;

    const history = useHistory();

    useEffect(() => {
        // Set the global navigation function
        axiosInstance.navigateToHome = () => {
            if (loginUser.user === undefined) {
                history.push("/"); // Redirect to the home page
                window.location.reload();
            }
        };
    }, [history, loginUser.user]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            console.log("Storage changed:", event);
            if (event.key === "user") {
                console.log("User  key detected.");
                if (event.oldValue !== null && (event.newValue === null || event.newValue === undefined)) {
                    // Handle user deletion
                    console.log("User  deleted from localStorage.");
                    // alert("You have deleted the user from localStorage.");
                    dispatch(fetchUserDetails());
                }
            }
        };

        window.addEventListener("storage", handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [dispatch]);

    // let userDetails = undefined;

    // const storedUser = localStorage.getItem("user");
    // if (storedUser) {
    //     userDetails = JSON.parse(storedUser); // Parse the stored user data
    // }

    // return (
    //     <>
    //         {user === undefined ? (
    //             <>
    //                 <ToastContainer />
    //                 <Route path="/" exact component={Login} />
    //                 {/* <Route path="*" exact component={Login} /> */}
    //             </>
    //         ) : (
    //             <div className={wrapperClass} onClick={onWrapperClick}>
    //                 <ToastContainer />
    //                 <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

    //                 <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
    //                 <div className="layout-sidebar" onClick={onSidebarClick}>
    //                     <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
    //                 </div>
    //                 <div className="layout-main-container">
    //                     <div className="layout-main">
    //                         <Switch>
    //                             <Route path="/company-management" exact component={Dashboard} />
    //                             <Route path="/product-management" exact component={ProductRights} />
    //                         </Switch>
    //                     </div>
    //                 </div>

    //                 <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

    //                 <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
    //                     <div className="layout-mask p-component-overlay"></div>
    //                 </CSSTransition>
    //             </div>
    //         )}
    //     </>
    // );

    return (
        <>
            <Router>
                <ToastContainer />
                <div className={user !== undefined ? "hidden" : ""}>
                    {/* Login Component */}
                    <Switch>
                        <Route path="/" exact component={Login} />
                    </Switch>
                </div>
                <div className={user === undefined ? "hidden" : ""}>
                    {/* Main Layout */}
                    <div className={wrapperClass} onClick={onWrapperClick}>
                        <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Switch>
                                    <Route path="/company-management" exact component={Dashboard} />
                                    <Route path="/product-management" exact component={ProductRights} />
                                </Switch>
                            </div>
                        </div>

                        <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                        <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                            <div className="layout-mask p-component-overlay"></div>
                        </CSSTransition>
                    </div>
                </div>
            </Router>
        </>
    );
};

export default App;
