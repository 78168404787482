import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { BsSearch, BsTrash } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import GlobalDialogIndex from "../../ui-components/globaldialoge";
import GlobalInputField from "../../ui-components/globalinputfield";
import PrimaryButton from "../../ui-components/primarybutton/primarybutton";
import AddEditRight from "./components/addEditRight";
import DeleteDialog from "./components/deleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { getProductRightList } from "../../redux/auth_slice/product_right_slice";
import { PaginatorTemplate } from "../../ui-components/paginatorTemplate/PaginatorTemplate";
import CustomLoading from "../../ui-components/customSpinner/custom_spinner";

const ProductRights = () => {
    // States
    const [isDialog, setIsDialog] = useState(false);
    const [editData, setEditData] = useState(null);
    const [delDialog, setDelDialog] = useState(false);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Search
    const [keyword, setKeyword] = useState(null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const [sortField, setSortField] = useState("id");
    const [sortOrder, setSortOrder] = useState("desc");

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);
    const [deleteId, setDeleteId] = useState();

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const payload = {
        page: page ? page : currentPage,
        per_page: pageSize,
        search_text: keyword,
        show_all: false,
    };

    if (sortField) {
        payload["sort_by"] = sortField;
    }
    if (sortOrder) {
        payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
    }

    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const dispatch = useDispatch();
    //Redux Selector
    const productRightListReducer = useSelector((state) => state.productRight);
    const { data, loading } = productRightListReducer;

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    dispatch(getProductRightList(payload));
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    useEffect(() => {
        dispatch(getProductRightList(payload));
    }, [showAll, sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        if (data?.data) {
            setPagination({
                ...pagination,
                totalRecords: data?.total,
                totalPages: data?.last_page,
                isLastPage: data?.last_page,
            });
        }
    }, [data]);

    // Custom body template
    const statusTemplate = (rowData) => {
        return (
            <>
                <div className="dp-flex">
                    <FaRegEdit
                        onClick={() => {
                            setIsDialog(true);
                            setEditData("Edit");
                            setEditData(rowData);
                        }}
                        style={{ fontSize: "16px", cursor: "pointer" }}
                    />
                    <BsTrash
                        style={{ fontSize: "16px", cursor: "pointer" }}
                        onClick={() => {
                            setDeleteId(rowData.id);
                            setDelDialog(true);
                        }}
                    />
                </div>
            </>
        );
    };
    return (
        <>
            <div className="grid">
                <div className="col-12 pt-24">
                    <h2>Product Rights</h2>
                </div>
                <div className="md:col-6"></div>
                <div className="col-12 md:col-4">
                    <GlobalInputField name="name" type="text" id="name" placeholder="Search by right name module name, display name" iconField={<BsSearch />} isRequired={false} onChange={(e) => setKeyword(e.target.value)} />
                </div>
                <div className="col-12 md:col-2">
                    <PrimaryButton label="Add Rights" onClick={() => setIsDialog(true)} style={{ width: "100%" }} />
                </div>
                {loading ? (
                    <CustomLoading />
                ) : (
                    <div className="col-12 pt-2">
                        <div className="custom_scroll">
                            <DataTable
                                value={data?.data}
                                responsiveLayout="scroll"
                                stripedRows
                                paginator
                                onSort={onSort}
                                sortable
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onPage={onCustomPage}
                                paginatorTemplate={PaginatorTemplate(pagination.isFirstPage, pagination.isLastPage, currentPage, pagination.totalPages, pageSize, page, pagination.totalRecords, pageInputTooltip, setCurrentPage, setPage, setPageSize, setPageInputTooltip)}
                                rows={pageSize}
                                // onRowClick={(event) => handleRowClick(event, event.data)}
                            >
                                <Column field="right_name" header="Right Name"></Column>
                                <Column field="module_name" header="Module Name"></Column>
                                <Column field="sub_modulename" header="Sub Module Name"></Column>
                                <Column field="display_name" header="Display Name"></Column>
                                <Column body={statusTemplate} header="Status"></Column>
                                {/* {dynamicColumns} */}
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
            {isDialog && (
                <GlobalDialogIndex
                    showHeader={true}
                    visible={isDialog}
                    onHide={() => {
                        setIsDialog(false);
                        setEditData(null);
                    }}
                    header={editData == null ? "Add Right" : "Edit Right"}
                    draggable={true}
                    breakpoints={{ "960px": "80vw", "640px": "90vw" }}
                    style={{ width: "50vw" }}
                    component={
                        <AddEditRight
                            isDialog={isDialog}
                            setIsDialog={setIsDialog}
                            editData={editData}
                            onHide={() => {
                                setIsDialog(false);
                                setEditData(null);
                            }}
                        />
                    }
                />
            )}
            {delDialog && (
                <GlobalDialogIndex
                    showHeader={true}
                    visible={delDialog}
                    onHide={() => setDelDialog(false)}
                    header={false}
                    draggable={false}
                    breakpoints={{ "960px": "80vw", "640px": "90vw" }}
                    style={{ width: "20vw" }}
                    component={<DeleteDialog onHide={() => setDelDialog(false)} deleteId={deleteId} />}
                />
            )}
        </>
    );
};

export default ProductRights;
