import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changeCompanyStatus, getCompanyList } from "../redux/auth_slice/company_user_slice";
import GlobalDialogIndex from "../ui-components/globaldialoge";
import GlobalInputField from "../ui-components/globalinputfield";
import GlobalVerticalDots from "../ui-components/globalverticaldots";
import PrimaryButton from "../ui-components/primarybutton/primarybutton";
import AddCompany from "./dashboardComponent";
import EditCompany from "./dashboardComponent/editCompany";
import CustomLoading from "../ui-components/customSpinner/custom_spinner";
import { PaginatorTemplate } from "../ui-components/paginatorTemplate/PaginatorTemplate";
import { toast } from "react-toastify";
import { resetCompanyUserSlice } from "../redux/auth_slice/company_user_slice";

const Dashboard = (props) => {
    const dispatch = useDispatch();
    //Redux Selector
    const companyListReducer = useSelector((state) => state.companyUser);
    const { data, loading, companyStatusLoading, companyStatusData, companyStatusError } = companyListReducer;

    const updateCompanyReducer = useSelector((state) => state.companyUser);
    const { updateSuccess } = updateCompanyReducer;

    const logoutReducer = useSelector((state) => state.loginUser);
    const { logoutLoading } = logoutReducer;

    useEffect(() => {
        if (data?.data) {
            setPagination({
                ...pagination,
                totalRecords: data?.total,
                totalPages: data?.last_page,
                isLastPage: data?.last_page,
            });
        }
    }, [data]);

    // States
    const [isDialog, setIsDialog] = useState(false);
    const [editData, setEditData] = useState();
    const [isEditSummary, setIsEditSummary] = useState(false);
    const [activeTab, setActiveTab] = useState("companySummary");
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Search
    const [keyword, setKeyword] = useState(null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const payload = {
        page: page ? page : currentPage,
        per_page: pageSize,
        search_text: keyword,
        show_all: false,
    };

    if (sortField) {
        payload["sort_by"] = sortField;
    }
    if (sortOrder) {
        payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
    }

    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const kebabMenuItems = [{ id: 1, title: "Edit", icon: <FaRegEdit style={{ fontSize: "16px" }} /> }];

    //Handlers
    const handleOpenMenuItems = (status, rowData) => {
        if (status === 1) {
            setIsEditSummary(true);
            setEditData("Edit");
            setEditData(rowData);
        }
    };

    // Custom body template
    const kbabMenuTemplate = (rowData) => {
        return (
            <>
                <div className="flex flex-row justify-content-between">
                    <span>{rowData.name}</span>
                    <GlobalVerticalDots items={kebabMenuItems} handleMenuOpen={(status) => handleOpenMenuItems(status, rowData)} />
                </div>
            </>
        );
    };
    // Custom body template
    const statusTemplate = (rowData) => {
        return (
            <InputSwitch
                checked={rowData.is_active}
                onChange={(e) => {
                    const newValue = e.value;
                    handleStatusChange(rowData, newValue);

                    if (companyStatusLoading === undefined || companyStatusLoading === false) {
                        dispatch(resetCompanyUserSlice());
                        dispatch(getCompanyList(payload));
                    }
                }}
            />
        );
    };

    const handleStatusChange = async (rowData, newValue) => {
        try {
            dispatch(
                changeCompanyStatus({
                    id: rowData.id, // Assuming `id` identifies the row
                    status: newValue === true ? 1 : 0, // New status
                })
            );
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    if (logoutLoading !== true || logoutLoading === undefined) {
                        dispatch(getCompanyList(payload));
                    }
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    useEffect(() => {
        if (logoutLoading !== true || logoutLoading === undefined) {
            dispatch(getCompanyList(payload));
        }
        
    }, [showAll, sortField, sortOrder, pageSize, currentPage, updateSuccess]);

    useEffect(() => {
        if (companyStatusData !== undefined) {
            toast.success("Company status updated successfully!");
        }

        if (companyStatusError !== undefined) {
            toast.success("Something went wrong, please try again!");
        }
    }, [companyStatusData, companyStatusError]);

    useEffect(() => {
        console.log("isEditSummary", isEditSummary);
        if (isEditSummary) {
            setActiveTab("companySummary");
        }
    }, [isEditSummary]);

    return (
        <>
            <div className="grid">
                <div className="col-12 pt-24">
                    <h2>Company Management</h2>
                </div>
                <div className="md:col-6"></div>
                <div className="col-12 md:col-4">
                    <GlobalInputField
                        name="name"
                        type="text"
                        id="name"
                        placeholder="Search by company name, poc name, email & phone"
                        iconField={<BsSearch />}
                        isRequired={false}
                        onChange={(e) => setKeyword(e.target.value)}
                        value={keyword}
                    />
                </div>
                <div className="col-12 md:col-2">
                    <PrimaryButton label="Add Company" onClick={() => setIsDialog(true)} style={{ width: "100%" }} />
                </div>
                {loading || companyStatusLoading ? (
                    <CustomLoading />
                ) : (
                    <>
                        <div className="col-12 pt-2">
                            <div className="custom_scroll">
                                <DataTable
                                    value={data?.data}
                                    emptyMessage="No record available."
                                    responsiveLayout="scroll"
                                    stripedRows
                                    paginator
                                    onSort={onSort}
                                    sortable
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    onPage={onCustomPage}
                                    paginatorTemplate={PaginatorTemplate(pagination.isFirstPage, pagination.isLastPage, currentPage, pagination.totalPages, pageSize, page, pagination.totalRecords, pageInputTooltip, setCurrentPage, setPage, setPageSize, setPageInputTooltip)}
                                    rows={pageSize}
                                >
                                    <Column body={kbabMenuTemplate} header="Company Name"></Column>
                                    <Column field="company_phone" header="Company Phone No"></Column>
                                    <Column field="zip_code" header="Zip Code"></Column>
                                    <Column field="poc_first_name" header="POC First Name"></Column>
                                    <Column field="poc_last_name" header="POC Last Name"></Column>
                                    <Column field="poc_email" header="POC Email"></Column>
                                    <Column field="authentication_type" header="Authentication Type"></Column>
                                    <Column body={statusTemplate} header="Status"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {isDialog && (
                <GlobalDialogIndex
                    className="custom-scroll-dialog"
                    showHeader={true}
                    visible={isDialog}
                    onHide={() => setIsDialog(false)}
                    header={"Add Company"}
                    draggable={true}
                    breakpoints={{ "960px": "80vw", "640px": "90vw" }}
                    style={{ width: "70vw" }}
                    component={
                        <AddCompany
                            onHide={() => {
                                setIsDialog(false);
                                setIsEditSummary(false);
                            }}
                            isDialog={isDialog}
                        />
                    }
                />
            )}
            {isEditSummary && (
                <GlobalDialogIndex
                    showHeader={true}
                    visible={isEditSummary}
                    onHide={() => setIsEditSummary(false)}
                    header={activeTab === "companySummary" ? "Company Summary" : "Company User"}
                    draggable={true}
                    breakpoints={{ "960px": "80vw", "640px": "90vw" }}
                    style={{ width: "70vw" }}
                    component={
                        <EditCompany
                            activeTab={activeTab}
                            editData={editData}
                            setActiveTab={setActiveTab}
                            onHide={() => {
                                setIsEditSummary(false);
                                setIsDialog(false);
                            }}
                            isEditSummary={isEditSummary}
                        />
                    }
                />
            )}
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);
