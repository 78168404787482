import Axios from "axios";
import appURL from "../constants/appURL";
import Cookies from "js-cookie";
import { loginUser } from "../redux/auth_slice/login_user_slice";

// Create Axios instance (if you're using one)
const axiosInstance = Axios.create();

// Setup Axios response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // If the error is a 401 Unauthorized, redirect to the home page
        if (error?.response?.status === 401 || error?.response?.status === 503) {
            if (axiosInstance.navigateToHome) {
                loginUser.user = undefined;
                Cookies.remove("access_token");
                localStorage.clear();
                // axiosInstance.navigateToHome(); // Call the navigation function
                window.location.href = "/"; // Redirect to home
            }
        }

        // Reject the error to propagate it to the calling code
        return Promise.reject(error);
    }
);

let refreshInterval;

function scheduleTokenRefresh() {
    console.log("scheduleTokenRefresh");
    refreshInterval = setInterval(async () => {
        try {
            await axiosInstance.post(appURL.baseUrl + appURL.refreshToken, null, { withCredentials: true });
        } catch (err) {
            console.error("Token refresh failed:", err);
            clearInterval(refreshInterval);
        }
    }, 50 * 60 * 1000); // Refresh every 50 minutes
}

// Call this function on app startup
scheduleTokenRefresh();

export default axiosInstance;
