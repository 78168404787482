const appURL = {
    // baseUrl: "http://localhost:8000/api/v1/",
    baseUrl: "https://devadminbe.factorflow.com/api/v1/",

    // Login
    adminLogin: "auth/login",
    fetchUserDetails: "auth/me",
    refreshToken: "auth/refresh",
    adminLogout: "auth/logout",

    // Company
    getCompanyList: "company/paginate",
    companyFetch: "company/fetch",
    companyAdd: "company/save",
    fetchcompanyById: "company/fetch_by_id",
    companyEdit: "company/save",
    companyDelete: "company/delete",
    changeCompanyStatus: "company/change_status",
    companyUserUpdate: "company/update_user",

    // User
    getCompanyUserList: "user/paginate",
    getCompanyUserFetch: "user/fetch",
    changeCompanyUserStatus: "user/change_status",

    // Product Rights
    getProductRightList: "right/paginate",
    addProductRight: "right/save",
    updateProductRight: "right/save",
    deleteProductRight: "right/delete",

    // global APIs
    getZipCodesList: "zip_codes/fetch",
    getZipCode: "zip_codes/fetch_code",
};

export default appURL;
