import React, { useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import PrimaryButton from "../../../ui-components/primarybutton/primarybutton";
import SecondaryButton from "../../../ui-components/secondarybutton/secondarybutton";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductRight, getProductRightList, resetProductRightSlice } from "../../../redux/auth_slice/product_right_slice";
import { toast } from "react-toastify";

const DeleteDialog = (props) => {
    const { onHide, deleteId } = props;

    const dispatch = useDispatch();

    // //Redux Selector
    const productRightDeleteReducer = useSelector((state) => state.productRight);
    const { deleteError, deleteSuccess } = productRightDeleteReducer;

    const handleDelete = () => {
        dispatch(deleteProductRight({ id: deleteId }));
    };

    useEffect(() => {
        if (deleteSuccess !== undefined) {
            if (deleteSuccess === true) {
                dispatch(getProductRightList());
                toast.warn("Right deleted successfully!");
                onHide();
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetProductRightSlice());
        };
    }, [deleteSuccess, deleteError, dispatch]);

    return (
        <>
            <div className="delete_Icon">
                <BsTrash />
            </div>
            <div className="delete_header">
                <h5>Delete Right</h5>
                <p>Are you sure you want to delete this?</p>
            </div>
            <div className="delete_button">
                <SecondaryButton label="Cancel" type="button" onClick={onHide} style={{ width: "100%" }} />
                <PrimaryButton label="Delete" type="button" onClick={handleDelete} style={{ width: "100%" }} />
            </div>
        </>
    );
};
export default DeleteDialog;
