import moment from 'moment';

const formatDate=(value)=>{
    return moment(value).format('M/DD/YYYY');
}

const formateDateTime=(value)=>{
    return moment(value).format('M/DD/YYYY HH:mm');
}

const formatTime=(value)=>{
    return moment(value).format('hh:mm a');
}

export {formatDate,formateDateTime,formatTime};