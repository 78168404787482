import appURL from "../../constants/appURL";
import axiosInstance from "../../service/axios_apis";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const companyUserMainList = createSlice({
    name: "companyUser",
    initialState: {},
    reducers: {
        resetCompanyUserSlice(state, action) {
            return { ...state, 
                addSuccess: undefined, 
                updateSuccess: undefined, 
                deleteSuccess: undefined, 
                updateCompanyUserSuccess: undefined, 
                updateCompanyUserError: undefined, 
                updateCompanyUserStatusSuccess: undefined, 
                updateCompanyUserLoading: undefined, 
                zipCodeLoading: undefined, 
                zipCodeData: undefined, 
                zipCodeError: undefined,
                companyStatusLoading: undefined,
                companyStatusData:undefined,
                companyStatusError:undefined,
                companyUserStatusLoading: undefined,
                companyUserStatusData: undefined,
                companyUserStatusError: undefined,
             };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getCompanyList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
        builder
            .addCase(addCompany.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addCompany.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addCompany.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(updateCompany.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateCompany.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateCompany.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });

        builder
            .addCase(getCompanyUserList.pending, (state, action) => {
                return { ...state, companyUserListLoading: true };
            })
            .addCase(getCompanyUserList.fulfilled, (state, action) => {
                return { ...state, companyUserListLoading: false, companyUserListData: action.payload };
            })
            .addCase(getCompanyUserList.rejected, (state, action) => {
                return {
                    ...state,
                    companyUserListLoading: false,
                    companyUserListError: action.payload,
                };
            });

        builder
            .addCase(updateCompanyUser.pending, (state, action) => {
                return { ...state, updateCompanyUserLoading: true };
            })
            .addCase(updateCompanyUser.fulfilled, (state, action) => {
                return { ...state, updateCompanyUserLoading: false, updateCompanyUserData: action.payload, updateCompanyUserSuccess: true };
            })
            .addCase(updateCompanyUser.rejected, (state, action) => {
                return {
                    ...state,
                    updateCompanyUserLoading: false,
                    updateCompanyUserError: action.payload,
                    updateCompanyUserSuccess: false,
                };
            });

        // Change Company User Status reducer
        builder
            .addCase(changeCompanyUserStatus.pending, (state, action) => {
                return { ...state, companyUserStatusLoading: true };
            })
            .addCase(changeCompanyUserStatus.fulfilled, (state, action) => {
                return { ...state, companyUserStatusLoading: false, companyUserStatusData: action.payload };
            })
            .addCase(changeCompanyUserStatus.rejected, (state, action) => {
                return {
                    ...state,
                    companyUserStatusLoading: false,
                    companyUserStatusError: action.payload,
                };
            });

        // ZipCode reducer
        builder
            .addCase(getZipCodesList.pending, (state, action) => {
                return { ...state, zipCodeLoading: true };
            })
            .addCase(getZipCodesList.fulfilled, (state, action) => {
                return { ...state, zipCodeLoading: false, zipCodeData: action.payload };
            })
            .addCase(getZipCodesList.rejected, (state, action) => {
                return {
                    ...state,
                    zipCodeLoading: false,
                    zipCodeError: action.payload,
                };
            });

        // Change Company Status reducer
        builder
            .addCase(changeCompanyStatus.pending, (state, action) => {
                return { ...state, companyStatusLoading: true };
            })
            .addCase(changeCompanyStatus.fulfilled, (state, action) => {
                return { ...state, companyStatusLoading: false, companyStatusData: action.payload };
            })
            .addCase(changeCompanyStatus.rejected, (state, action) => {
                return {
                    ...state,
                    companyStatusLoading: false,
                    companyStatusError: action.payload,
                };
            });

        // Fetch Company By Id reducer
        builder
            .addCase(fetchcompanyById.pending, (state, action) => {
                return { ...state, fetchByIdLoading: true };
            })
            .addCase(fetchcompanyById.fulfilled, (state, action) => {
                return { ...state, fetchByIdLoading: false, fetchByIdData: action.payload };
            })
            .addCase(fetchcompanyById.rejected, (state, action) => {
                return {
                    ...state,
                    fetchByIdLoading: false,
                    fetchByIdError: action.payload,
                };
            });
    },
});
export default companyUserMainList.reducer;
export const { resetCompanyUserSlice } = companyUserMainList.actions;
// Thunks
export const getCompanyList = createAsyncThunk("company/paginate", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.getCompanyList, body, { withCredentials: true });
        return fulfillWithValue(data?.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

export const addCompany = createAsyncThunk("company/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.companyAdd, body, { withCredentials: true });
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
export const updateCompany = createAsyncThunk("company/update", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.companyEdit, body, { withCredentials: true });
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

export const getCompanyUserList = createAsyncThunk("user/paginate", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.getCompanyUserList, body, { withCredentials: true });
        return fulfillWithValue(data?.users);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

export const updateCompanyUser = createAsyncThunk("user/save", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.companyUserUpdate, body, { withCredentials: true });
        return fulfillWithValue(data?.user);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

// Company User Change Status Thunk
export const changeCompanyUserStatus = createAsyncThunk("user/change_status", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.changeCompanyUserStatus, body, { withCredentials: true });
        return fulfillWithValue(data?.status);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

// ZipCode Thunk
export const getZipCodesList = createAsyncThunk("zip_codes/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.getZipCode, body, { withCredentials: true });
        return fulfillWithValue(data?.code);
    } catch (error) {
        if (error.response) {
            // Use the error message returned from the server
            return rejectWithValue(error.response.data.error || "An unknown error occurred");
        } else if (error.request) {
            // No response was received
            return rejectWithValue("No response from the server");
        } else {
            // Something went wrong while making the request
            return rejectWithValue(error.message || "An error occurred");
        }
    }
});

// Change Status Thunk
export const changeCompanyStatus = createAsyncThunk("company/change_status", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.changeCompanyStatus, body, { withCredentials: true });
        return fulfillWithValue(data?.status);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

// Fetch Company By Id
export const fetchcompanyById = createAsyncThunk("company/fetch_by_id", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.fetchcompanyById, body, { withCredentials: true });
        return fulfillWithValue(data?.company);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
