import React, { useEffect, useState } from "react";
import SecondaryButton from "../../../ui-components/secondarybutton/secondarybutton";
import PrimaryButton from "../../../ui-components/primarybutton/primarybutton";
import GlobalInputField from "../../../ui-components/globalinputfield";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyUserList, getZipCodesList, resetCompanyUserSlice, updateCompanyUser } from "../../../redux/auth_slice/company_user_slice";
import * as Yup from "yup";
import { toast } from "react-toastify";

const EditUser = (props) => {
    const { onHide, editUserData, companyId } = props;

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const zipCodeReducer = useSelector((state) => state.companyUser);
    const { zipCodeData } = zipCodeReducer;

    const updateCompanyUserReducer = useSelector((state) => state.companyUser);
    const { updateCompanyUserSuccess, updateCompanyUserError, updateCompanyUserLoading } = updateCompanyUserReducer;

    // State for managing form values
    const [formValues, setFormValues] = useState({
        firstName: editUserData?.first_name || "",
        lastName: editUserData?.last_name || "",
        userName: editUserData?.username || "",
        email: editUserData?.email || "",
        address: editUserData?.address || "",
        zipCode: editUserData?.zip_code || "",
        state: editUserData?.state || "",
        city: editUserData?.city || "",
    });

    useEffect(() => {
        if (formValues?.zipCode?.length === 5) {
            dispatch(getZipCodesList({ zip: formValues.zipCode }));
        } else {
            setFormValues((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    }, [formValues?.zipCode]);

    useEffect(() => {
        if (zipCodeData) {
            setFormValues((prevState) => ({
                ...prevState,
                city: zipCodeData.city,
                state: zipCodeData.full_state,
            }));
        }
    }, [zipCodeData]);

    // Handler for input changes
    const handleInputChange = (name, value) => {
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required").max(50, "Max 50 characters"),
        lastName: Yup.string().required("Last Name is required").max(50, "Max 50 characters"),
        userName: Yup.string().required("User Name is required").max(50, "Max 50 characters"),
        email: Yup.string().required("Email is required").email("Invalid email address"),
        address: Yup.string().required("Address is required").max(100, "Max 100 characters"),
        zipCode: Yup.string()
            .required("Zip Code is required")
            .test("length", "Zip Code must be 5 digits", (value) => !value || value.length === 5),
        state: Yup.string().required("State is required").max(50, "Max 50 characters"),
        city: Yup.string().required("City is required").max(50, "Max 50 characters"),
    });

    const validateForm = async (formValues) => {
        try {
            await validationSchema.validate(formValues, { abortEarly: false });
            setErrors({});
            return {};
        } catch (err) {
            const errors = {};
            err.inner.forEach((error) => {
                errors[error.path] = error.message;
            });
            setErrors(errors);
            return errors;
        }
    };

    const handleUpdate = async () => {
        const errors = await validateForm(formValues);

        if (Object.keys(errors).length > 0) {
            console.log(errors);
            return;
        }
        const payload = {
            id: editUserData?.id || 0,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            username: formValues.userName,
            email: formValues.email,
            address: formValues.address,
            zip_code: formValues.zipCode,
            state: formValues.state,
            city: formValues.city,
        };

        payload.id = editUserData.id;

        dispatch(updateCompanyUser(payload));
    };

    useEffect(() => {
        if (updateCompanyUserSuccess !== undefined) {
            if (updateCompanyUserSuccess === true) {
                toast.success("User updated successfully!");
                dispatch(getCompanyUserList({ company_id: companyId }));
                onHide();
            } else {
                toast.error(updateCompanyUserError);
            }
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [updateCompanyUserSuccess, updateCompanyUserError]);

    useEffect(() => {
        const validate = async () => {
            const validationErrors = await validateForm(formValues);
            setErrors(validationErrors);
        };

        validate();
    }, [formValues]);

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="firstName" id="firstName" label="First Name" placeholder="Enter First Name" value={formValues.firstName} isRequired={true} onChange={(e) => handleInputChange("firstName", e.target.value)} maxLength={50} />
                    {errors.firstName && <small className="p-error">{errors.firstName}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="lastName" id="lastName" label="Last Name" placeholder="Enter Last Name" value={formValues.lastName} isRequired={true} onChange={(e) => handleInputChange("lastName", e.target.value)} maxLength={50} />
                    {errors.lastName && <small className="p-error">{errors.lastName}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="email" id="email" label="Email Address" placeholder="Enter Email Address" value={formValues.email} isRequired={true} onChange={(e) => handleInputChange("email", e.target.value)} maxLength={50} />
                    {errors.email && <small className="p-error">{errors.email}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="userName" id="userName" label="User Name" placeholder="Enter User Name" value={formValues.userName} isRequired={true} onChange={(e) => handleInputChange("userName", e.target.value)} maxLength={50} />
                    {errors.userName && <small className="p-error">{errors.userName}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="address" id="address" label="Address" placeholder="Enter Address" value={formValues.address} isRequired={true} onChange={(e) => handleInputChange("address", e.target.value)} maxLength={50} />
                    {errors.address && <small className="p-error">{errors.address}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="zipCode" id="zipCode" label="Zip Code" placeholder="Enter Zip Code" value={formValues.zipCode} isRequired={true} onChange={(e) => handleInputChange("zipCode", e.target.value)} maxLength={5} />
                    {errors.zipCode && <small className="p-error">{errors.zipCode}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="state" id="state" label="State" placeholder="Enter State" value={formValues.state} isRequired={true} onChange={(e) => handleInputChange("state", e.target.value)} maxLength={50} disabled />
                    {errors.state && <small className="p-error">{errors.state}</small>}
                </div>
                <div className="col-12 md:col-6">
                    <GlobalInputField type="text" name="city" id="city" label="City" placeholder="Enter City" value={formValues.city} isRequired={true} onChange={(e) => handleInputChange("city", e.target.value)} maxLength={50} disabled />
                    {errors.city && <small className="p-error">{errors.city}</small>}
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="dp-flex justify-content-end mt-3">
                        <SecondaryButton type="button" name="cancelButton" id="cancelButton" label="Cancel" onClick={onHide} />
                        <PrimaryButton type="submit" name="saveButton" id="saveButton" label="Update" onClick={handleUpdate} loading={updateCompanyUserLoading} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditUser;
