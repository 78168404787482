import React from "react";
//Styles
import styles from "./globalinputfield.module.css";
//P-Components
import { InputText } from "primereact/inputtext";
const GlobalInputField = (props) => {
    const { name, id, placeholder, onClick, onChange, style, iconField, label, isRequired, small, disabled,value,maxLength } = props;
    return (
        <>
            {label && (
                <div className="d-flex mb-2">
                    <label>
                        <b>{label}</b>
                        {isRequired ? <span className="clr_red">*</span> : ""}
                        <span className={styles.small_text}>{small}</span>
                    </label>
                </div>
            )}
            {iconField ? (
                <div className="p-input-icon-right d-block">
                    <i className="pi pi-search" />
                    <InputText className={styles.input_styles} placeholder={placeholder} onChange={onChange} onClick={onClick} id={id} name={name} style={style} disabled={disabled} value={value} maxLength={maxLength} />
                </div>
            ) : (
                <InputText className={styles.input_styles} placeholder={placeholder} onChange={onChange} onClick={onClick} id={id} name={name} style={style} disabled={disabled} value={value} maxLength={maxLength} />
            )}
        </>
    );
};
export default GlobalInputField;
