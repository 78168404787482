import { configureStore } from "@reduxjs/toolkit";
import loginUserReducer, { fetchUserDetails } from "./auth_slice/login_user_slice";
import company_user_slice from "./auth_slice/company_user_slice";
import product_right_slice from "./auth_slice/product_right_slice";

const user = localStorage.getItem("user") ? localStorage.getItem("user") : undefined;
const initialState = {
    loginUser: {
        user: user ? JSON.parse(user) : undefined,
    },
};
const store = configureStore({
    reducer: {
        loginUser: loginUserReducer,
        companyUser: company_user_slice,
        productRight: product_right_slice,
    },
    preloadedState: initialState,
});

if (user !== undefined) {
    store.dispatch(fetchUserDetails());
}

// console.log("Adding storage event listener...");
// // Listen for changes in localStorage (e.g., user deleted)
// window.addEventListener("storage", (event) => {
//     console.log("Storage changed:", event.oldValue, event.newValue);
//     if (event.key === "user" && event.oldValue !== null && event.newValue === null) {
//         // Handle user deletion
//         loginUser.user = undefined;
//         console.log("User deleted from localStorage.");
//         store.dispatch(fetchUserDetails());
//         window.location.reload();
//     }
// });

export default store;
